

import { defineComponent } from 'vue';

import Hero from '@/components/Hero.vue';

interface Therapy {
  name: string
  description: string
}

export default defineComponent({
  components: { Hero },

  methods: {
    sectionClasses(index: number) {
      const classes = 'row align-items-center how-it-works d-flex'.split(' ');
      if (index % 2 === 1) {
        classes.push('justify-content-end');
      }
      return classes;
    },
    sectionBodyClasses(index: number) {
      const classes = ['col-6'];
      if (index % 2 === 0) {
        classes.push('order-last');
      } else {
        classes.push('text-right');
      }
      return classes;
    },
    sectionNumberClasses(index: number) {
      const classes = ['col-2', 'text-center', 'd-inline-flex', 'justify-content-center',
        'align-items-center', 'position-relative'];
      if (index % 2 === 0) {
        classes.push('bottom');
      } else {
        classes.push(...['full', 'order-last']);
      }
      return classes;
    },
    timelineCornerClasses(index: number) {
      if (index % 2 === 0) {
        return [['corner', 'top-right'], ['corner', 'left-bottom']];
      }
      return [['corner', 'right-bottom'], ['corner', 'top-left']];
    },
  },

  data() {
    return {
      hero: 'werkwijze-1178909761.webp',
      procedure: [
        {
          id: 'aanmelden',
          title: 'Aanmelden',
          content: `
          <p>
            Passend bij mijn persoonlijke benadering heb ik de voorkeur voor een telefonische
            aanmelding.
            Op deze manier kunnen we kort kennismaken en kan ik beter beoordelen of ik de juiste
            zorg in huis heb om je verder te helpen.
            Je kunt me telefonisch bereiken (niet tijdens behandelingen) op maandag tot en met vrijdag:
          </p>

          <p>
            Nadat we elkaar gesproken hebben ontvang je een bevestiging en een uitnodiging voor een
            nadere kennismaking en de intake.
          </p>

          <div class="d-flex flex-wrap justify-content-center">
            <a href="tel:+31627146096" class="btn btn-outline-dark me2 mb-4">
              <i class="bi-telephone" role="img" aria-label="Telefoon"></i>
              Bel me voor het maken van een afspraak
            </a>
          </div>
          `,
        },
        {
          id: 'intake',
          title: 'De intake',
          content: `
          <p>
            Een intake bestaat uit twee gesprekken; een intakegesprek en een adviesgesprek.
            Bij het intakegesprek brengen we je probleem, klacht(en) en hulpvraag in kaart.
            We bespreken <strong>wat</strong> je doel is, <strong>wat</strong> je wilt bereiken en
            <strong>wat</strong> bij jou past?
            Uiteraard help ik je hierbij en kijken we samen naar de mogelijkheden.
          </p>

          <p>
            Na het intakegesprek zal een zorgvraagtypering plaatsvinden waarbij ik de vragenlijst
            genaamd HoNOS+ invul.
            Deze vragenlijst bepaalt welk zorgvraagtype het beste bij jouw klachten en problemen
            past.
            Deze informatie zullen we tijdens het adviesgesprek bespreken om vervolgens samen een
            behandelplan te bepalen.
            Tijdens het adviesgesprek maken we samen een inschatting van de behandelduur en
            bespreken we de hoogte van de behandelkosten.
            Ik hanteer hiervoor de tarieven zoals vastgesteld door de
            <a href="https://www.nza.nl/">Nederlandse Zorgautoriteit</a>&nbsp;(NZa).
            We bespreken samen of dit past bij jouw verwachtingen en wensen.
          </p>
          <p>
            Bij het intakegesprek neem je het volgende mee:
            <ul>
              <li>Geldige verwijsbrief van de huisarts (indien van toepassing)</li>
              <li>Geldig identiteitsbewijs</li>
              <li>Zorgpolispasje</li>
            </ul>
          </p>
          `,
        },
        {
          id: 'overeenkomst-en-behandelplan',
          title: 'De behandeling',
          content: `
          <p>
            Tijdens de intakefase hebben we een (behandel)overeenkomst bereikt en samen een
            behandelplan opgesteld.
            In het behandelplan worden realistische en haalbare doelen geformuleerd.
            Ook wordt er een inschatting gemaakt van:
          </p>
          <ul>
            <li>het aantal gesprekken</li>
            <li>de frequentie van de gesprekken</li>
            <li>de duur van het volledige traject</li>
          </ul>

          <p>
            In de behandelovereenkomst worden ook belangrijke afspraken vastgelegd rondom o.a.;
            privacy, tarieven en vergoedingen, bereikbaarheid en klachten.
          </p>
          `,
        },
        {
          id: 'voortgangsgesprek',
          title: 'Voortgangsbespreking',
          content: `
          <p>
            Gedurende het behandeltraject monitor ik op gezette tijden de voortgang.
            Op basis daarvan zal gekeken worden of de behandeling bijgesteld moet worden, er
            voldoende progressie is geboekt en of de behandeling wellicht beëindigd kan worden.
            Uiteraard gebeurt dit altijd in afstemming met jou.
          </p>
          `,
        },
        {
          id: 'afronding-en-evaluatie',
          title: 'Afronding en evaluatie',
          content: `
          <p>
            Bij de afsluiting van het behandeltraject vindt er een eindevaluatie plaats.
            Tijdens de eindevaluatie blikken we samen terug op de behandeling en zie ik toe op een
            zorgvuldige afronding.
            Uiteraard kijken we of je doelen gehaald zijn en/of dat je voldoende tools in handen
            hebt om zelfstandig verder te gaan.
          </p>
          `,
        },
      ],
      therapies: [
        {
          name: 'Cognitieve gedragstherapie',
          description: `
            Bij cognitieve gedragstherapie leer je hoe je beter om kunt gaan met negatieve gedachten
            en gevoelens over jezelf en/of je omgeving.
          `,
        },
        {
          name: 'ACT',
          description: `
            ACT (Acceptance and Commitment Therapy) is een therapievorm waar je naast het leren
            omgaan met negatieve gedachtes en onaangename gevoelens (acceptance) vooral leert om je
            te richten op wat écht belangrijk is in je leven (commitment).
          `,
        },
        {
          name: 'EMDR',
          description: `Eye Movement Desensitization and Reprocessing, afgekort EMDR, is een
            therapie voor mensen die last blijven houden van de gevolgen van een schokkende
            ervaring, zoals een ongeval, seksueel geweld of een geweldsincident. Daarnaast is de
            therapie geschikt voor het versterken van je zelfbeeld; bijvoorbeeld wanneer je als kind
            werd gepest.
          `,
        },
        {
          name: 'Schematherapie',
          description: `
            Schematherapie is een vorm van psychotherapie en helpt je de oorsprong van hardnekkige
            denk-, gevoelens en gedragspatronen te doorgronden en veranderen.
          `,
        },
        {
          name: 'Motiverende gespreksvoering',
          description: `
            Motiverende gespreksvoering heeft als doel om gedragsverandering op gang te brengen. In
            de eerste fase staat het ontwikkelen van de motivatie om te veranderen centraal. De
            tweede fase richt zich op het versterken van de betrokkenheid bij veranderingen en op
            het ontwikkelen van een plan om de verandering te realiseren.
          `,
        },
        {
          name: 'Interpersoonlijke psychotherapie',
          description: `Dit is een therapievorm waarbij je inzicht krijgt en bewust wordt gemaakt
            van je onbewuste patronen.
          `,
        },
      ] as Therapy[],
    };
  },
});
